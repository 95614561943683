// import React, { useLayoutEffect } from "react";

import { isSafari } from "react-device-detect";
import "./MainFooter.scss";

const MainFooter = (props) => {
  const FooterClass = isSafari ? "position-relative" : "";
  return (
    <footer className={`main-footer ${FooterClass}`}>{props.children}</footer>
  );
};

export default MainFooter;
